import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const EntryHeader = ({ title, date, icon = false, format, featured = false, bannerOverlay = "green", featuredImage }) => {

	const image = getImage( icon );
	const heroBg = getImage( featuredImage );
	const bannerOverlayMap = {
		"green": "bg-green-550/80",
		"yellow": "bg-gold-400/80",
		"orange": "bg-gold-450/80",
		"teal": "bg-green-700/80"
	}

	return (  

		<div className={`text-center border-b ${featured ? `${bannerOverlayMap[bannerOverlay]} grid w-full`: 'pb-8 md:pb-20 py-20 px-4 md:px-0' } relative`}>
			{ featured &&
				<GatsbyImage
					image={heroBg}
					alt={`Featured image for ${title}`}
					fullWidth={true}
					className="col-start-1 col-end-2 row-start-1 row-end-2 -z-[10]"
				/>
			}
			{ featured ?
				<div className="col-start-1 col-end-2 row-start-1 row-end-2 flex flex-col justify-center items-center my-6">
					<div className={`flex justify-center items-center gap-4 mb-4 md:mb-8 lg:mb-12 text-sm uppercase text-gray-600 tracking-widest z-10 relative ${ featured && 'text-white z-10 relative'}`}>
						{ icon && 
							<GatsbyImage image={image} alt="title" width="36" />
						}
						{ format &&
							format 
						}
					</div>
					<h1 className={`max-w-3xl mx-auto ${ featured && 'text-white z-10 relative'}`}>
						{title}
					</h1>
					<span className={`text-sm text-gray-600 uppercase tracking-widest z-10 relative ${ featured && 'text-white z-10 relative'}`}>{date}</span>
				</div>
			:
				<>
					<div className={`flex justify-center items-center gap-4 mb-4 md:mb-8 lg:mb-12 text-sm uppercase text-gray-600 tracking-widest z-10 relative ${ featured && 'text-white z-10 relative'}`}>
						{ icon && 
							<GatsbyImage image={image} alt="title" width="36" />
						}
						{ format &&
							format 
						}
					</div>
					<h1 className={`max-w-3xl mx-auto ${ featured && 'text-white z-10 relative'}`}>
						{title}
					</h1>
					<span className={`text-sm text-gray-600 uppercase tracking-widest z-10 relative ${ featured && 'text-white z-10 relative'}`}>{date}</span>
				</>
			}
		</div>
	);
}

export default EntryHeader;


